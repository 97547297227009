<template>
	<div :class="rootClasses">
		<img class="image" :src="data.image" alt="img">
	</div>
</template>

<script>
export default {
	name: 'ImageBlock',
	props: {
		data: {
			type: Object,
			default: () => { },
		},
	},
	data() {
		return {
		}
	},
	computed: {
		rootClasses() {
			let result = {
				['cs-image-block']: true,
				['theme-block']: true,
				['background-is-content']: true
			};

			if (this.data?.hasBackground) result = {
				...result,
				...{
					['has-background']: true,
					['theme-bg-colored']: true
				}
			}

			return result;
		},

		textAlignment() {
			return (this.data.textAlign == "center") ? "center" : "left";
		},
		titleAlignment() {
			return (this.data.titleAlign == "center") ? "center" : "left";
		},
	},
	methods: {
	},
	mounted() {
	}
}
</script>

<style lang="scss" scoped>
@import '../styles/_mixins.scss';

.image {
	float: center;
	object-fit: cover;
	width: 100%;
	height: auto;
	max-height: 100vh;
}
</style>
